<template>
  <div>
    <v-form ref="form" class="mb-4">
      <div class="heading text-center mb-7 dark--text">Войти в приложение</div>

      <div class="text-center mb-7">
        <img :src="$store.getters['app/logo']" height="80" />
      </div>

      <v-text-field
        class="mb-4"
        label="Телефон"
        v-mask="phoneMask"
        type="tel"
        outlined
        hide-details
        v-model="phone"
        :rules="[(v) => !!v]"
        @input="onPhoneInput"
      />
      <v-text-field
        class="mb-4"
        label="Пароль"
        type="password"
        outlined
        hide-details
        v-model="password"
        :rules="[(v) => !!v || 'Введите пароль']"
      />
      <v-checkbox
        color="primary"
        value="true"
        v-model="rules"
        required
        :rules="[(v) => !!v || 'Согласитесь с правилами, чтобы продолжить!']"
      >
        <template slot="label">
          Я согласен
          <router-link to="/rules" class="rules-link">с правилами</router-link>
        </template>
      </v-checkbox>
    </v-form>

    <div class="text-center">
      <v-btn text class="forgot-pass-btn" :to="{ name: 'ForgotPass' }"
        >Забыли пароль?</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import actionButtonMixin from '@/mixins/actionButton'
import authMixin from '@/mixins/authMixin'

const fields = ['phone', 'password']

let computed = {}

fields.forEach((name) => {
  computed[name] = {
    get() {
      return this.$store.state.auth[name]
    },
    set(value) {
      const { commit } = this.$store
      commit('auth/onChange', { name, value })
    },
  }
})

export default {
  mixins: [actionButtonMixin, authMixin],

  data: () => ({
    rules: false,
  }),

  computed: {
    ...computed,

    actionButton() {
      return {
        active: true,
        text: 'Войти',
        action: this.submit,
      }
    },

    ...mapGetters('app', ['phoneMask', 'phonePrefix']),
  },

  methods: {
    async submit() {
      if (!this.validate()) return
      let { phone: username, password } = this
      username = username.replace(/\D+/g, '')
      const response = await this.login({ username, password })
      if (response.code === 1) {
        this.$emitter.emit('login', this.phone)
        this.$store.commit('auth/resetAuth')
      }
    },

    validate() {
      return this.$refs.form.validate()
    },

    ...mapActions('user', ['login']),
  },
}
</script>

<style lang="scss" scoped>
.forgot-pass-btn {
  text-decoration: underline;
}
</style>
